<template>
  <div>
    <feed-back-status-active :is-feed-back-status-active-sidebar-active.sync="isFeedBackStatusActiveSidebarActive
      " :editPropsStatusActive="editPropsStatusActive" @changeStatusFeedBack="changeStatusFeedBack" />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="6" md="4">
            <user-dropdown :options="status" :selected-option="selectedStatus" labelValue="Select Status" @getUserDropDown="statusDropdown" placeholder="All"/>
          </b-col>
          <b-col cols="6" md="4">
            <user-dropdown :options="appUsers" :selected-option="selectReporter" labelValue="Reported By" @getUserDropDown="reporterDropdown" placeholder="All" />
          </b-col>
          <b-col  cols="6" md="4" v-if="shouldShowFeedback">
            <user-dropdown :options="users" :selected-option="selectGenerator" labelValue="Question Creator" @getUserDropDown="generatorDropdown" placeholder="All"/>
          </b-col>
          <b-col cols="6" md="4" v-if="shouldShowFeedback">
            <user-dropdown :options="users" :selected-option="selectAssign" labelValue="Assigned To" @getUserDropDown="assignDropdown" placeholder="All"/>
          </b-col>
          <b-col cols="6" md="4">
              <user-dropdown :options="topics" :selected-option="SelectedTopic" labelValue="Select Topic" @getUserDropDown="selectTopic" placeholder="All"/>
          </b-col>
          <b-col cols="6" md="4" >
            <div class="d-flex w-100 gap-2" style="justify-content: space-between;">
              <div>
                <date-range-component tittle="Select Date Range" :dateRange="dateRange" 
                @dateRangeSelected = "changeDateRangeFeedbacks"/>
              </div>
              <div>
                <user-dropdown :options="perPageOptions" :selected-option="perPage" labelValue="Per Page" @getUserDropDown="perPageDropdown" placeholder="All"/>
              </div>
            </div>
          </b-col>
          <b-col cols class="d-flex justify-content-end align-items-end">
        <div class="">{{ from }} - {{ to }} of {{ totalItems }}</div>
    </b-col>
        </b-row>
      </div>

      <b-overlay :show="show" rounded="sm">
        <template v-if="feedbackData.total !== 0">
          <b-table :items="feedbackData.data" responsive :fields="Helpers.hideObjectFromArrayByKey(fields, ['path','action'], TokenService.getPermissions('manage_feedback'))" class="mb-0" id="custom-table">
            <template #cell(date)="data">
              <span v-if="data.item.reply && data.item.reply[0]">
                {{ data.item.reply[0].replyDate }} {{ data.item.reply[0].replyTime }}
              </span>
              <span v-else>
                -
              </span>
            </template>
            <template #cell(reported_date)="data">
              <span v-if="data.item.reply && data.item.reply[1]">
                {{ formatDate(data.item.reply[1].replyDate) }} {{ data.item.reply[1].replyTime }}
              </span>
              <span v-else>
                -
              </span>
            </template>

            <template #cell(reply)="data">
              <div class="d-flex align-items-center">
                <div v-for="(question_data, ind) in data.item.reply" :key="ind + '23434'">
                  <div v-if="ind == 0">
                    {{ question_data.userName }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(message)="data">
              <div class="d-flex align-items-center" @click="
                functionPassingProps(
                  data.item.questionId,
                  data.item.className,
                  data.item.subjectName,
                  data.item.categoryName,
                  data.item.categoryId,
                  data.item.levelName
                )
                ">
                <div v-for="(question_data, ind) in data.item.reply" :key="ind + '234fsa34'">
                  <div v-if="ind == 0">
                    {{ question_data.reply }}
                    <feather-icon size="18" icon="EyeIcon" @click="showReplies(data.item)" />

                    <div v-if="TokenService.getPermissions('manage_feedback')">
                      <span>
                        <b-link :to="{
                          path: '/categorylevels/question/' + data.item.levelId + '/' + data.item.questionId, query: { categoryId: data.item.categoryId, theme: data.item.themeQuestion ? true : false }
                        }">
                          <feather-icon size="18" icon="ArrowRightIcon" />
                        </b-link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(screenshot)="data">
              <div class="d-flex align-items-center">
                <div v-for="(question_data, ind) in data.item.reply" :key="ind + '234134'">
                  <div v-if="ind == 0">
                    <div v-if="question_data.screenShot">
                      <img @click="modelOpen(question_data.screenShot)" style="width: 50px; height: 50px"
                        :src="question_data.screenShot" />
                    </div>
                    <div v-else>N.A</div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(path)="data">
              <div class="d-flex align-items-center">
                <div>
                  <b-link :to="{
                    path: '/categorylevels/question/' + data.item.levelId + '/' + data.item.questionId, query: { categoryId: data.item.categoryId, theme: data.item.themeQuestion ? true : false }
                  }">
                    {{ data.item.className + "/" + data.item.subjectName + "/" + data.item.categoryName + "/"+  data.item.levelName + "/"}}Questions

                  </b-link>
                  <!-- <b-link :to="{
                    path: '/categorylevels/question/' + data.item.levelId + '/' + data.item.questionId, query: { categoryId: data.item.categoryId, theme: data.item.themeQuestion ? true : false }
                  }">
                  Questions
                  </b-link> -->
                </div>
              </div>
            </template>

            <template #cell(status)="data">
              <div class="d-flex align-items-center">
                <div>
                  {{ data.item.status }}
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <div class="d-flex align-items-center">
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item @click="
                    openSideBarStatusActive(
                      data.item._id,
                      data.item.status,
                      data.item.assginfeedBackIds
                    )
                    ">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit Status</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </template>
        <template v-else>
          <div class="text-center mb-2">No records found</div>
        </template>
        <div style="display: flex; flex-direction: row-reverse">
          <div>
            <!-- <pagination class="m-t-10" :data="feedbackData" :limit=2 @pagination-change-page="getFeedbackData">
            </pagination> -->

            <b-pagination v-model="currentPage" :total-rows="feedbackData.total"  :limit=2 :per-page="feedbackData.per_page"
               @change="getFeedbackData" class="m-t-10" >
                </b-pagination>
          </div>
        </div>
      </b-overlay>
      <screenshot-modal v-if="showScreenshot" :modelImage="modelImage" tittle="Feedback ScreenShot" />
      <feedback-modal v-if="showFeedback"
        modelImage="https://zape02.s3.ap-southeast-1.amazonaws.com/0.010193975588125070.39484294051567080.5217275479947419.jpeg"
        :feedbackDatashow="feedbackDatashow" tittle="Feedback" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BOverlay,
  BFormGroup,
  BTableSimple,
  BThead,
  BFormSelect,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import FeedBackStatusActive from "./app/list/users-list/FeedBackStatusActive.vue";
import UserDropdown from "../views/components/UserDropdown.vue";
import ScreenshotModal from "../views/components/ScreenshotModal.vue";
import FeedbackModal from "../views/components/FeedbackModal.vue";
import DateRangeComponent from "../views/components/DateRangeComponent.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TokenService from "../TokenService";
import * as Helpers from "../Helpers";
export default {
  components: {
    FeedBackStatusActive,
    UserDropdown,
    ScreenshotModal,
    FeedbackModal,
    DateRangeComponent,
    BBreadcrumb,
    BCard,
    BOverlay,
    BFormGroup,
    BAvatar,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    ToastificationContent,
    DateRangePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      dateRange: {},
      removeButtons: false,
      showScreenshot: false,
      showFeedback: false,
      table_data: {},
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      totalItems: 0,
      from: 0,
      to: 0,
      show: false,
      feedbackDatashow: {},
      status: ["All", "Pending", "Active", "Resolved", "Discard"],
      users: [],
      appUsers: [],
      selectGenerator: {},
      selectAssign: {},
      selectReporter: {},
      searchQuery: "",
      fields: [
        { key: "date", label: "Date Time" },
        { key: "reported_date", label: "Reported Date Time" },
        { key: "reply", label: "USER" },
        { key: "message", label: "MESSAGE" },
        { key: "screenshot", label: "SCREENSHOT" },
        { key: "path", label: "PATH" },
        { key: "status", label: "STATUS" },
        { key: "action", label: "ACTION" },
      ],
      selectedStatus: "All",
      isAddNewUsersSidebarActive: false,
      isAddEditUsersSidebarActive: false,
      editDataProps: {},
      DeleteUserAlertMessage: "",
      isAddChangePasswordSidebarActive: false,
      changePasswordId: "",
      isFeedBackStatusActiveSidebarActive: false,
      assignUserId: {
        id: "",
      },
      feedbackData: [],
      table_: [],
      saveStatusFilter: [],
      editPropsStatusActive: {
        id: "",
        status: "",
        message: "",
      },
      modelImage: "",
      startDate: '',
      endDate: '',
      notificationMessage:"",
      permission:"",
      SelectedTopic:'All',
      topics:[],
      TokenService,
      Helpers,
    };
  },
  computed: {
    shouldShowFeedback() {
      return this.permission === 'show_all_feedback';
    }
  },
  created() {
    if(!TokenService.getPermissions("view_feedback") && !TokenService.getPermissions("show_all_feedback")) {
      this.$router.push("/error-404");
    }
    // console.log("TokenService.getPermissions", TokenService.getPermissions(), TokenService.getPermissions("view_feedback1"));
    const topicId = this.$route.query.topicId;
    var a = new Date();
    var b = -30;
    var resulta = a.setDate(a.getDate() + b);
    var someDate = new Date();
    var numberOfDaysToAdd = 0;
    var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    this.dateRang = {};
    this.dateRange = {
      endDate: new Date(result),
      startDate: new Date(resulta),
    };
    var startDate = this.dateRange.startDate;
    var endDate = this.dateRange.endDate;
    var month = startDate.getMonth() + 1;
    var day = startDate.getDate();
    var year = startDate.getFullYear();
    if (day >= 10) {
    } else {
      day = "0" + day;
    }
    if (month >= 10) {
    } else {
      month = "0" + month;
    }
    var startDate = year + "-" + month + "-" + day;
    var month = endDate.getMonth() + 1;
    var day = endDate.getDate();
    var year = endDate.getFullYear();
    if (day >= 10) {
    } else {
      day = "0" + day;
    }
    if (month >= 10) {
    } else {
      month = "0" + month;
    }
    var lastDate = year + "-" + month + "-" + day;
    this.startDate = startDate;
    this.endDate = lastDate; var startDate = this.dateRange.startDate;
    var endDate = this.dateRange.endDate;
    var month = startDate.getMonth() + 1;
    var day = startDate.getDate();
    var year = startDate.getFullYear();
    if (day >= 10) {
    } else {
      day = "0" + day;
    }
    if (month >= 10) {
    } else {
      month = "0" + month;
    }
    var startDate = year + "-" + month + "-" + day;
    var month = endDate.getMonth() + 1;
    var day = endDate.getDate();
    var year = endDate.getFullYear();
    if (day >= 10) {
    } else {
      day = "0" + day;
    }
    if (month >= 10) {
    } else {
      month = "0" + month;
    }
    var lastDate = year + "-" + month + "-" + day;
    this.startDate = startDate;
    this.endDate = lastDate;
    let allUserObj = {
      _id: 0,
      name: 'All'
    }
    this.selectGenerator = allUserObj;
    this.selectReporter = allUserObj;
    this.selectAssign = allUserObj;
    this.getFeedbackDetails();
    this.getFeedbackData();
  },
  methods: {
    getTopicFromTopicId(topicId) {
      // Use the find method to get the topic where _id matches topicId
      const topic = this.topics.find(topic => topic._id === topicId);
      console.log('topic', topicId, topic, this.topics);
      return topic || null; // Return the topic or null if not found
    },
    selectTopic(data) {
      this.SelectedTopic = data;
      this.getFeedbackData();
    },
    statusDropdown(data){
      this.selectedStatus = data
      this.getFeedbackData();
    },
    reporterDropdown(data) {
      this.selectReporter = data
      this.getFeedbackData();
    },
    generatorDropdown(data) {
      this.selectGenerator = data
      this.getFeedbackData();
    },
    assignDropdown(data) {
      this.selectAssign = data
      this.getFeedbackData();
    },
    perPageDropdown(data) {
      this.perPage = data
      this.changePerPage()();
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.notificationMessage,
          variant,
        },
      });
    },
    changePerPage() {
      this.getFeedbackData();
    },


    formatDate(dateStr) {

      if (typeof dateStr === 'string') {
        const [month, day, year] = dateStr.split('/').map(num => parseInt(num, 10));
        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) {
          console.error('Invalid date:', dateStr);
          return '-';
        }

        const options = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        };

        return date.toLocaleDateString('en-GB', options);
      } else {
        console.warn('Invalid date string format:', dateStr);
        return '-';
      }
    },


    changeDateRangeFeedbacks(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.getFeedbackData();
    },
    getFeedbackData: function (page = 1, query) {
      this.show = true;
      query = this.searchQuery;
      let status = this.selectedStatus;
      let generatorId = this.selectGenerator && this.selectGenerator._id ? this.selectGenerator._id : 0;
      let reporterId = this.selectReporter && this.selectReporter._id.$oid ? this.selectReporter._id.$oid : 0;
      let assigneId = this.selectAssign && this.selectAssign._id ? this.selectAssign._id : 0;
      let topicId = this.SelectedTopic && this.SelectedTopic._id ? this.SelectedTopic._id : 0;
      axios
        .get(process.env.VUE_APP_API_URL + `/feedback-data/all?page=${page}&query=${query}&status=${status}&startdate=${this.startDate.toString()}&enddate=${this.endDate.toString()}&generatorId=${generatorId}&assignId=${assigneId}&reporterId=${reporterId}&perPage=${this.perPage}&topicId=${topicId}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
          timeout: 10000,
        })
        .then((get_response) => {
          this.feedbackData = get_response.data.feedbacks;
          this.from = this.feedbackData.from !== null ? this.feedbackData.from : 0;
          this.to = this.feedbackData.to !== null ? this.feedbackData.to : 0;
          this.totalItems = this.feedbackData.total;
          this.notificationMessage = get_response.data.message;
          this.permission = get_response.data.permission;
          // this.showToasted("success");
          this.show = false;
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            this.notificationMessage = 'Request timed out. Please try again later.';
          } else if (error.response) {
            this.notificationMessage = error.response.data.message;
          } else {
            this.notificationMessage = 'An error occurred. Please try again later.';
        }
        console.log('error=', error.response)
        this.showToasted("danger");
        this.show = false;
    });
        },
    getFeedbackDetails: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/feedback/details", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
          timeout: 10000,
        })
        .then((get_response) => {
          let allUserObj = {
            _id: 0,
            name: 'All'
          }
          let users = get_response.data.users;
          let appUsers = get_response.data.appUsers;
          this.selectGenerator = allUserObj;
          this.selectReporter = allUserObj;
          this.selectGenerator.users = users;
          this.selectReporter.appUsers = appUsers;
          this.topics = get_response.data.topics;
          this.selectTopic(this.getTopicFromTopicId(this.$route.query.topicId));
          appUsers.push(allUserObj);
          users.push(allUserObj);
          this.users = users.reverse();
          this.appUsers = appUsers.reverse();

        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            this.notificationMessage = 'Request timed out. Please try again later.';
          } else if (error.response) {
            this.notificationMessage = error.response.data.message;
          } else {
            this.notificationMessage = 'An error occurred. Please try again later.';
          }
          this.showToasted("danger");
          this.show = false;
        });
    },
    // 
    showReplies(replies) {
      this.feedbackDatashow = replies.reply;
      this.showFeedback = true;
      this.$root.$emit("bv::show::modal", "modal-feedback");
    },
    modelOpen(img) {
      this.modelImage = img;
      this.showScreenshot = true;
      this.$root.$emit("bv::show::modal", "modal-screenshot");
    },
    openSideBarStatusActive(id, status, assginIds) {
      this.editPropsStatusActive.id = id;
      if (assginIds != undefined) {
        this.editPropsStatusActive.assginIds = assginIds;
      } else {
        this.editPropsStatusActive.assginIds = [];
      }
      this.editPropsStatusActive.status = status;
      this.isFeedBackStatusActiveSidebarActive = true;
    },
    changeStatusFeedBack(sendActiveStatus) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/feedback/update",
          sendActiveStatus,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.notificationMessage = response.data.message;
          this.showToasted("success");
          this.getFeedbackData(this.feedbackData.current_page);
          this.$forceUpdate();
        })
        .catch((error) => {
          if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            this.notificationMessage = 'Request timed out. Please try again later.';
          } else if (error.response) {
            this.notificationMessage = error.response.data.message;
          } else {
            this.notificationMessage = 'An error occurred. Please try again later.';
          }
          console.log('error=', error.response)
          this.showToasted("danger");
          this.show = false;
        });
    },
    functionPassingProps(
      levelId,
      classNAme,
      subjectName,
      categoryName,
      catgeoryid,
      levelName
    ) {
      let a = JSON.stringify(levelId);
      localStorage.setItem("feedbackQuestionId", a);
      let b = JSON.stringify(classNAme);
      localStorage.setItem("className", b);
      let c = JSON.stringify(subjectName);
      localStorage.setItem("subjectName", c);
      let d = JSON.stringify(categoryName);
      localStorage.setItem("categoryName", d);
      let e = JSON.stringify(catgeoryid);
      localStorage.setItem("categoryId", e);
      let f = JSON.stringify(levelName);
      localStorage.setItem("level", f);
    },


    sendPropsToLevel(classNAme, subjectName, categoryName, catgeoryid) {
      let a = JSON.stringify(classNAme);
      localStorage.setItem("className", a);
      let b = JSON.stringify(subjectName);
      localStorage.setItem("subjectName", b);
      let c = JSON.stringify(categoryName);
      localStorage.setItem("categoryName", c);
      let d = JSON.stringify(catgeoryid);
      localStorage.setItem("categoryId", d);
    },


  },
};
</script>

<style lang="scss">
img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}

.modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
}

.table td {
  padding: 1rem !important;
}

.table th {
  padding: 1rem !important;
}

.per-page-selector {
  width: 90px;
}

.padding_table {
  padding: 0.72rem 1.4rem !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.dropdown button {
  padding: 0 !important;
}

.dropdown-menu {
  min-width: 8rem;
  margin-top: -17px !important;
}

.dropdown a.dropdown-item {
  padding: 0px 8px;
}
</style>
<style>
.vs__open-indicator {
  fill: #9d9d9d;
}
</style>
