<template>
    <b-form-group style="margin-top: 7px;" :label="tittle">
      <date-range-picker
        ref="picker"
        :opens="'left'"
        :locale-data="{
          direction: 'ltr',
          format: 'mm-dd-yyyy',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0,
        }"
        :timePicker="false"
        :timePicker24Hour="true"
        :showWeekNumbers="false"
        :showDropdowns="true"
        :autoApply="true"
        v-model="dateRange"
        @update="handleDateRangeUpdate"
        :linkedCalendars="true"
      ></date-range-picker>
        <!-- :minDate="'2019-01-01 00:00:00'"
        :maxDate="'2099-01-01 00:00:00'" -->
    </b-form-group>
  </template>
  
  <script>
  import {BFormGroup} from "bootstrap-vue";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  export default {
    components:{
        DateRangePicker,
        BFormGroup
    },
    props: {
    tittle: {
      type: String,
      default: 'Select Date Range',
    },
    dateRange: {
      type: Object,
      default: () => [],
    },
  },
    data() {
      return {
        localeData: {
          direction: 'ltr',
          format: 'mm-dd-yyyy',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0,
        },
      };
    },
    methods: {
      handleDateRangeUpdate(value) {
        var startDate = value.startDate;
        var endDate = value.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        var formattedStartDate = year + "-" + month + "-" + day;
  
        month = endDate.getMonth() + 1;
        day = endDate.getDate();
        year = endDate.getFullYear();
        if (day < 10) {
          day = "0" + day;
        }
        if (month < 10) {
          month = "0" + month;
        }
        var formattedEndDate = year + "-" + month + "-" + day;
  
        // Emit the formatted date range to the parent component
        this.$emit('dateRangeSelected', {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your scoped styles here */
  </style>
  